<template>
  <a-card>
    <a-page-header title="巨量广告素材报表" style="margin-top: -20px; margin-bottom: " />
    <a-form-model :model="form" v-bind="layoutTop">
      <a-row type="flex" justify="start" style="margin-bottom: -20px">
        <a-col :span="12">
          <a-form-model-item label="素材类型">
            <a-radio-group v-model="form.adMaterialType">
              <a-radio v-for="value in materiaList" :key="value.value" :value="value.value">
                {{ value.text }}
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="统计方式">
            <a-radio-group v-model="form.statisticType">
              <a-radio v-for="value in methodsTypeList" :key="value.value" :value="value.value">
                {{ value.text }}
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <a-form-model :model="form" v-bind="layoutBottom">
      <a-row type="flex" justify="start" style="margin-bottom: -20px">
        <a-col :span="7">
          <a-form-model-item label="查询周期">
            <a-range-picker
              v-model="pickerTime"
              value-format="YYYY-MM-DD"
              :disabled-date="disabledDate"
              :allow-clear="false"
              @calendarChange="calendarChange"
              @openChange="openChange"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="5">
          <a-form-model-item label="素材名称">
            <a-input v-model.trim="form.title" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <a-col :span="5">
          <a-form-model-item label="素材ID">
            <a-input v-model.trim="form.adMaterialId" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <a-col :span="5">
          <a-form-model-item label="广告主">
            <a-input v-model.trim="form.advertiserName" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="7">
          <a-form-model-item label="平台账套ID">
            <a-input v-model.trim="form.adPlatformAccountId" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <a-col :span="5">
          <a-form-model-item label="所属品牌">
            <a-select v-model="form.principalId" placeholder="请选择">
              <a-select-option v-for="item in principalList" :key="item.principalId" :value="item.principalId">
                {{ item.principalName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="10">
          <div style="text-align: right">
            <a-button style="margin-right: 15px" :loading="exportBtnLoading" @click="handleExport">导出数据</a-button>
            <a-button @click="handleReset">重置</a-button>
            <a-button style="margin: 0 18px 0 20px" type="primary" @click="handleSearch">查询</a-button>
          </div>
        </a-col>
      </a-row>
      <a-table
        class="table"
        size="small"
        :loading="tableLoading"
        :columns="columns"
        :data-source="dataList"
        :rowKey="(record, index) => index"
        :pagination="false"
        :scroll="{ x: 2600 }"
        @change="handleTableChange"
      >
        <div slot="calculateCycle" slot-scope="text, record">
          {{ record.timeString }}
        </div>
        <div slot="materialSlot">
          <div>素材名称</div>
          <div>素材ID</div>
        </div>
        <div slot="adMaterialId" slot-scope="text, record">
          <div class="blue-text" @click="handleOpenPage(record.adMaterialId)">{{ record.title }}</div>
          <div class="gray-text">ID: {{ record.adMaterialId }}</div>
        </div>
        <div slot="previewUrl" slot-scope="text, record" class="img-container">
          <img
            v-show="record.previewUrl"
            style="cursor: pointer"
            :src="record.previewUrl"
            alt="视频封面"
            @click="handleOpenPage(record.adMaterialId)"
          />
        </div>
        <div slot="advUserSlot">
          <div>广告主</div>
          <div>平台套账ID</div>
        </div>
        <div slot="advertiserName" slot-scope="text, record">
          <div>{{ record.advertiserName }}</div>
          <div class="gray-text">ID: {{ record.adPlatformAccountId }}</div>
        </div>
      </a-table>
      <base-pagination
        :currentPage="pagination.current"
        :pageSize="pagination.pageSize"
        :total="pagination.total"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
      />
    </a-form-model>
  </a-card>
</template>

<script>
import moment from 'moment';
import { materiaList, methodsTypeList } from './typeList';
import { downloadExcel } from '@/utils';
import resizeMixin from '@/mixins/dataTableMixin';

export default {
  mixins: [resizeMixin],
  data() {
    const columns = [
      {
        align: 'center',
        fixed: 'left',
        title: '统计周期',
        width: 200,
        key: 'calculateCycle',
        scopedSlots: { customRender: 'calculateCycle' },
      },
      {
        fixed: 'left',
        width: 220,
        align: 'center',
        key: 'adMaterialId',
        slots: { title: 'materialSlot' },
        scopedSlots: { customRender: 'adMaterialId' },
      },
      {
        fixed: 'left',
        title: '预览图',
        align: 'center',
        width: 150,
        scopedSlots: { customRender: 'previewUrl' },
      },
      {
        fixed: 'left',
        width: 180,
        align: 'center',
        key: 'advertiserName',
        slots: { title: 'advUserSlot' },
        scopedSlots: { customRender: 'advertiserName' },
      },
      {
        align: 'center',
        sorter: true,
        title: '消费(元)',
        key: 'statCost',
        width: 80,
        customRender(text) {
          return text.metric.statCost;
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '目标转化数',
        key: 'convertCnt',
        width: 80,
        customRender(text) {
          return text.metric.convertCnt;
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '转化单价(元)',
        key: 'convertCost',
        width: 80,
        customRender(text) {
          return text.metric.convertCost;
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '展示数',
        key: 'showCnt',
        width: 80,
        customRender(text) {
          return text.metric.showCnt;
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '千次展示单价(元)',
        key: 'thousandShowCost',
        width: 80,
        customRender(text) {
          return text.metric.thousandShowCost;
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '点击数',
        key: 'clickCnt',
        width: 80,
        customRender(text) {
          return text.metric.clickCnt;
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '点击单价(元)',
        key: 'clickCost',
        width: 80,
        customRender(text) {
          return text.metric.clickCost;
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '展示转化率(%)',
        key: 'showConvertRate',
        width: 80,
        customRender(text) {
          return text.metric.showConvertRate;
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '点击率(%)',
        key: 'clickRate',
        width: 80,
        customRender(text) {
          return text.metric.clickRate;
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '点击转化率(%)',
        key: 'clickConvertRate',
        width: 80,
        customRender(text) {
          return text.metric.clickConvertRate;
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '私信数',
        key: 'messageAction',
        width: 80,
        customRender(text) {
          return text.metric.messageAction || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '私信留资数',
        key: 'clueMessageCount',
        width: 80,
        customRender(text) {
          return text.metric?.clueMessageCount || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '私信留资率(%)',
        key: 'clueMessageCountRate',
        width: 80,
        customRender(text) {
          return text.metric?.clueMessageCountRate || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '私信留资单价（元）',
        key: 'unitPriceMessageAction',
        width: 80,
        customRender(text) {
          return text.metric?.unitPriceMessageAction || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '表单提交数',
        key: 'form',
        width: 80,
        customRender(text) {
          return text.metric?.form || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '智能电话接通数',
        key: 'phoneConnect',
        width: 80,
        customRender(text) {
          return text.metric?.phoneConnect || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '播放量',
        key: 'totalPlayCnt',
        width: 80,
        customRender(text) {
          return text.metric?.totalPlayCnt || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '完播率',
        key: 'playOverRate',
        width: 80,
        customRender(text) {
          return text.metric?.playOverRate || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '品牌名称',
        key: 'principalName',
        width: 80,
        customRender(text) {
          return text?.principalName || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '素材标签',
        key: 'labels',
        width: 100,
        customRender(text) {
          return text?.labels.length ? text?.labels.join(',') : '-';
        },
      },
    ];
    return {
      materiaList,
      methodsTypeList,
      layoutTop: {
        labelCol: { span: 4 },
        wrapperCol: { span: 15 },
      },
      layoutBottom: {
        labelCol: { span: 7 },
        wrapperCol: { span: 15 },
      },
      form: {
        adMaterialType: 'VIDEO',
        statisticType: 'TOTAL',
        principalId: undefined,
      },
      // 默认查询30天前
      pickerTime: [
        moment().subtract(30, 'days').format('YYYY-MM-DD'),
        moment().subtract(1, 'days').format('YYYY-MM-DD'),
      ],
      selectStartDay: '',
      tableLoading: false,
      columns,
      pagination: {
        current: 1,
        pageSize: 50,
        total: 0,
      },
      dataList: [],
      sortList: [{ fieldName: 'statCost', orderType: 'desc' }],
      exportBtnLoading: false,
      principalList: [],
    };
  },
  created() {
    this.getDataList();
    this.getPrincipalList();
  },
  methods: {
    getPrincipalList() {
      this.handleRequest('getAdvertiserPrincipalList', (data) => {
        this.principalList = data;
      });
    },
    async getDataList(isExportCheck = false) {
      // isExportCheck 导出查询
      const startTime = `${moment(this.pickerTime[0]).format('YYYY-MM-DD')} 00:00:00`;
      const endTime = `${moment(this.pickerTime[1]).format('YYYY-MM-DD')} 23:59:59`;
      const params = {
        ...this.form,
        startTime,
        endTime,
        sortList: this.sortList,
        page: isExportCheck ? 1 : this.pagination.current,
        size: this.pagination.pageSize,
      };
      const returnData = {
        params,
        total: 0,
      };
      const callbackFn = (data) => {
        if (!isExportCheck) {
          this.dataList = data.list.map((value) => {
            // 时间范围显示
            if (value.statStartTime) {
              switch (this.form.statisticType) {
                case 'TOTAL':
                  value.timeString = `${value.statStartTime} ~ ${value.statEndTime}`;
                  break;
                case 'GROUP_BY_DAY':
                  value.timeString = `${value.statStartTime}`;
                  break;
                case 'GROUP_BY_MONTH':
                  value.timeString = `${moment(value.statStartTime).format('YYYY-MM')}`;
                  break;
              }
            } else {
              value.timeString = '-';
            }
            return value;
          });
          this.pagination.total = data.total || 0;
        } else {
          // 返回导出检查条数
          returnData.total = data.total || 0;
        }
      };
      if (!isExportCheck) this.tableLoading = true;
      await this.handleRequest(
        'getMaterialList',
        (data) => {
          callbackFn(data);
        },
        params
      );
      this.tableLoading = false;
      return returnData;
    },
    async handleExport() {
      const startTime = moment(this.pickerTime[0]);
      const endTime = moment(this.pickerTime[1]);
      const betweenDays = endTime.diff(startTime, 'day');
      if (this.form.statisticType === 'GROUP_BY_DAY' && betweenDays > 31)
        return this.$message.info('按日统计的查询周期不能＞31天，请调整查询周期后再设置');
      this.exportBtnLoading = true;
      // 先检查当前查询条件条数
      const { params, total } = await this.getDataList(true);
      if (!total) {
        // 当前查询条件无结果 不处理导出
        this.$message.error('无内容，请重新设置查询条件');
      } else {
        const { blob, fileName } = await this.$api.core.ReleaseEffectReport.exportMaterialList(params);
        downloadExcel(blob, decodeURIComponent(fileName));
        this.$message.success('操作成功');
      }
      this.exportBtnLoading = false;
    },
    calendarChange(dates, dateStrings) {
      this.selectStartDay = dates[0];
    },
    openChange(status) {
      this.selectStartDay = '';
    },
    disabledDate(current) {
      if (this.form.statisticType === 'GROUP_BY_DAY') {
        return (
          current > this.$moment(this.selectStartDay).add(31, 'days') ||
          current < this.$moment(this.selectStartDay).subtract(31, 'days')
        );
      } else {
        return (
          current > this.$moment(this.selectStartDay).add(366, 'days') ||
          current < this.$moment(this.selectStartDay).subtract(366, 'days')
        );
      }
    },
    handleTableChange(pagination, filters, sorter) {
      const sort = sorter.order
        ? [
            {
              fieldName: sorter.columnKey,
              orderType: sorter.order == 'ascend' ? 'asc' : sorter.order == 'descend' ? 'desc' : '',
            },
          ]
        : [];
      this.sortList = sort;
      this.pagination.current = 1;
      this.getDataList();
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDataList();
    },
    handleReset() {
      this.form = this.$options.data().form;
      this.pickerTime = this.$options.data().pickerTime;
      this.selectStartDay = '';
    },
    handleSearch() {
      const startTime = moment(this.pickerTime[0]);
      const endTime = moment(this.pickerTime[1]);
      const betweenDays = endTime.diff(startTime, 'day');
      if (this.form.statisticType === 'GROUP_BY_DAY' && betweenDays > 31)
        return this.$message.info('按日统计的查询周期不能＞31天，请调整查询周期后再设置');
      this.pagination.current = 1;
      this.getDataList();
    },
    handleOpenPage(id) {
      this.handleRequest(
        'getMaterialMediaUrl',
        (data) => {
          window.open(data, '_blank');
        },
        id
      );
    },
    async handleRequest(API, callback, params = null) {
      const { code, message, data } = await this.$api.core.ReleaseEffectReport[API](params);
      if (code === 200) {
        callback(data);
      } else {
        this.$message.error(`${message}`);
      }
    },
  },
};
</script>

<style scoped lang="less">
.img-container {
  width: 100%;
  text-align: center;
  
  img {
    height: 75px;
    // height: 100%;
    object-fit: cover;
  }
}

.gray-text {
  color: #bbbbbb;
}

.blue-text {
  color: #599af8;
  cursor: pointer;
  text-decoration: underline;
}
.table {
  /deep/ .ant-table-row td {
    height: 92px;
  }
}
</style>
